import CircleCheck from "../SVG/CircleCheck";
import { MAIN_CONFIG } from "../../../config/main";
import FailIcon from "../SVG/FailIcon";
import PropTypes from "prop-types";
import Alert from "../SVG/Alert";

const iconMap = {
  success: (
    <CircleCheck
      className="flash-icon"
      width={25}
      height={25}
      secondaryColor={MAIN_CONFIG.COLORS.fillWhite}
      color={MAIN_CONFIG.COLORS.fillGreen}
      stroke={MAIN_CONFIG.COLORS.fillWhite}
    />
  ),
  alert: (
    <Alert
      className="flash-icon"
      color={MAIN_CONFIG.COLORS.fillRed}
      secondaryColor={MAIN_CONFIG.COLORS.fillRed}
    />
  ),
  error: <FailIcon className="flash-icon" />,
};

const FlashMessage = ({ type = "success", message = "" }) => {
  const icon = iconMap[type] || iconMap.success;

  return (
    <>
      {icon}
      <p className="BodyDefaultRegularBlack" id="toast-message-body">
        {message}
      </p>
    </>
  );
};

FlashMessage.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

export default FlashMessage;